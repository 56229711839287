/* eslint-disable react/display-name */
import React from 'react'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'

import Headline from '~components/detail/headline'
import CalloutCard from '~components/detail/callout-card'
import Pullquote from '~components/detail/pullquote'
import { Button, Bold, Italic } from '~components/shared'

// Sample helper. Usage:
// import { zebraRowClasses } from 'PATH/TO/constants/styles'
export const zebraRowClasses = ['bg-white', 'bg-gray']

export const contentfulReactComponentsOptions = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <Headline level='1' contents={children} />,
    [BLOCKS.HEADING_2]: (node, children) => <Headline level='2' contents={children} headingType={node?.headingType} />,
    [BLOCKS.HEADING_3]: (node, children) => <Headline level='3' contents={children} />,
    [BLOCKS.HEADING_4]: (node, children) => <Headline level='4' contents={children} />,
    [BLOCKS.HEADING_5]: (node, children) => <Headline level='5' contents={children} />,
    [BLOCKS.HEADING_6]: (node, children) => <Headline level='6' contents={children} />,
    [BLOCKS.PARAGRAPH]: (node, children) => <p className='body-4'>{children}</p>,
    [BLOCKS.OL_LIST]: (node, children) => <ol className='ordered-list'>{children}</ol>,
    [BLOCKS.UL_LIST]: (node, children) => <ul className='list'>{children}</ul>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      if (node.data.target?.fields?.file) {
        return (
          <img
            src={node.data.target?.fields?.file?.url + '?fm=jpg&fl=progressive&w=960&q=80'}
            alt={node.data.target?.fields?.description || 'No description available.'}
            title={node.data.target?.fields?.description || 'No description available.'}
          />
        )
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      if (node.data.target?.sys?.contentType?.sys?.id) {
        const entryTypeRaw = node.data.target?.sys?.contentType?.sys?.id
        const entryTypeFormatted = entryTypeRaw.toLowerCase()
        switch (entryTypeFormatted) {
          case 'button':
            return <Button href={node.data.target?.fields?.externalLink}>{node.data.target?.fields?.title}</Button>
          case 'callout':
            return <CalloutCard content={node.data.target?.fields?.callout} type={node.data.target?.fields?.type} />
          case 'quote':
            return (
              <Pullquote
                quote={node.data.target?.fields?.quote}
                author={node.data.target?.fields?.person}
                caption={node.data.target?.fields?.personSubline || ''}
              />
            )
          default:
            break
        }
      }
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const url = node.data?.uri
      return (
        <a href={url} title='hyperlink' className='underline'>
          {children}
        </a>
      )
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const url = node.data?.target?.fields?.file.url
      return (
        <a
          href={url}
          target='_blank' // eslint-disable-line react/jsx-no-target-blank
          title='asset hyperlink'
          className='underline'
        >
          {children}
        </a>
      )
    }
  }
}
