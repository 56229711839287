import React from 'react'
import { FormattedMessage } from 'react-intl'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { contentfulReactComponentsOptions } from '~constants/styles'

const Book = props => {
  const page = props.data.page

  return (
    <div className='flex flex-col lg:flex-row mt-8'>
      <div className='lg:w-2/5 mt-8 lg:mt-0 lg:pr-4'>
        {page?.bookImage?.file && (
          <img
            src={page.bookImage.file.url + '?fm=jpg&fl=progressive&w=960&q=80'}
            className='w-full h-auto object-contain'
            alt='N/A'
            aria-hidden
          />
        )}
      </div>
      <div className='lg:w-3/5 lg:pl-4'>
        <div className='body-4'>
          <div className='contentful'>
            {documentToReactComponents(page?.bookDescription?.json, contentfulReactComponentsOptions)}
          </div>
        </div>
        <div className='flex'>
          <div>
            <div className='button button-pink mt-4 mb-0 mr-4'>
              <a href={page?.bookDownload?.file?.url} target='_blank' rel='noopener noreferrer'>
                <FormattedMessage id='download-pdf' /> <em className='fas fa-caret-right ml-2' />
              </a>
            </div>
          </div>
          <div>
          { page?.bookLink !== null && 
            <div className='button button-blue mt-4 mb-0'>
              <a href={page?.bookLink} target='_blank' rel='noopener noreferrer'>
                <FormattedMessage id='read-online' /> <em className='fas fa-caret-right ml-2' />
              </a>
            </div>
          }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Book
