import React from 'react'

const AnchorLink = props => {
  const anchor = '#' + props.content[0].value.toString().toLowerCase().replace(/[^\w]/gi, '')

  return (
    <li>
      <a href={anchor} className='inline-flex font-bold text-xl text-blue leading-snug'>
        <div className='flex-shrink-0'>
          <div className='icon-small bg-purple text-white mr-4'>
            <em className='fas fa-caret-right' />
          </div>
        </div>
        {props.content[0].value}
      </a>
    </li>
  )
}

export default AnchorLink
