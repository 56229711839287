import React from 'react'
import { graphql } from 'gatsby'
import { Auth } from 'aws-amplify'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { contentfulReactComponentsOptions } from '~constants/styles'
import { useEffectOnce } from '~hooks'
import { createMarkup, getUser, setUser } from '~utilities'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Sidebar from '~components/sidebar'
import Book from '~components/detail/book'
import Breadcrumbs from '~components/detail/breadcrumbs'
import Login from '~components/detail/login'
import Recommended from '~components/detail/recommended'
import SeriesInformation from '~components/detail/series-information'
import Share from '~components/detail/share'
import ShareButton from '~components/detail/share-button'
import Sources from '~components/detail/sources'
import TableOfContents from '~components/detail/table-of-contents'
import YouTube from '~components/detail/youtube'
import { Tags } from '~components/shared'

const DetailPage = props => {
  const _ = require(`lodash`)
  const { section, locale } = props.pageContext
  const { location } = props

  const { page, sidebarContent } = props.data
  const { showFeaturedVideo, showFeaturedActivities } = page

  const user = getUser()

  let articlesPreviouslySeen = user && user?.['custom:articlesSeen'] ? JSON.parse(user['custom:articlesSeen']) : []

  // This trims the oldest seen article if the user has already seen 80 or 81
  // articles. 82 articles would overflow AWS Amplify's custom attribute limit
  // of 2048 characters since each marked article consumes 25 characters,
  // including the contentful_id, two quotation marks, and trailing comma
  if (articlesPreviouslySeen.length >= 80) {
    articlesPreviouslySeen = articlesPreviouslySeen.splice(0, 1)
  }

  let articlesCurrentlySeen = []
  if (articlesPreviouslySeen.includes(page.contentful_id)) {
    articlesCurrentlySeen = articlesPreviouslySeen
  } else {
    articlesCurrentlySeen = articlesPreviouslySeen.concat([page.contentful_id])
  }

  const handleUpdate = async articlesSeen => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(currentUser, {
        'custom:articlesSeen': JSON.stringify(articlesSeen)
      })
      const updatedUser = await Auth.currentAuthenticatedUser()
      const userInfo = {
        ...updatedUser.attributes
      }
      setUser(userInfo)
    } catch (error) {
      console.error(error)
    }
  }

  useEffectOnce(() => {
    if (user) {
      handleUpdate(articlesCurrentlySeen)
    }
  })

  // construct array of primary and secondary tags
  let tags = page?.primaryTopics || []
  if (page?.secondaryTopics) {
    tags = tags.concat(page?.secondaryTopics)
  }

  // book and video detail page series information
  let seriesInformation = []

  // filter the array of all secondary topics into an array including only the first one which includes a series description
  if (page.type === ('Book' || 'Video') && page.secondaryTopics && page.secondaryTopics.length > 0) {
    seriesInformation = _.get(
      page.secondaryTopics.filter(topic => topic.seriesDescription),
      '[0].seriesDescription.internal.content'
    )
  }

  let activityContent = []
  let activityJson = {}

  if (page.type === 'Activity') {
    activityContent = page.contents.json.content.map((previous, index, all) => {
      const next = all[index + 1]
      if (previous.nodeType === 'heading-2' && next && next.nodeType === 'unordered-list') {
        return { ...previous, headingType: 'list' }
      } else {
        return previous
      }
    })
    activityJson = {
      data: {},
      content: activityContent,
      nodeType: 'document'
    }
  }

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO
        lang={locale}
        title={page?.metaTitle || page.title}
        description={page?.metaDescription}
        articleId={page.contentful_id}
      />
      <div id={page.type}>
        <div className='max-w-page pt-4 lg:py-8'>
          <Breadcrumbs
            title={page.title}
            type={page.type}
            primaryTopics={page.primaryTopics}
            secondaryTopics={page.secondaryTopics || null}
          />
        </div>
        <hr className='accent-rule hidden lg:block' aria-hidden />
        <div className='flex flex-col lg:flex-row max-w-page py-4 lg:py-8'>
          <div className='w-full lg:w-3/4 pr-4'>
            <div className='headline-1'>{page.title}</div>

            <div className='py-4'>
              <hr className='accent-rule' aria-hidden />
              <div className='flex flex-col lg:flex-row justify-between lg:items-center py-4'>
                <Tags tags={tags} />
                <div className='flex-shrink-0 mt-4 lg:mt-0 lg:pl-2'>
                  <ShareButton label='share-page' />
                </div>
              </div>
              <hr className='accent-rule' aria-hidden />
            </div>

            {page.type === 'Video' && <YouTube url={page?.youtubeVideoId} />}

            <div
              className='intro my-4 lg:my-8 contentful'
              dangerouslySetInnerHTML={page.subline.childMarkdownRemark.html |> createMarkup}
            />

            {page.type === 'Article' && <TableOfContents contents={page?.contents?.json?.content} />}

            {(page.type === 'Article' || page.type === 'Video') && (
              <div className='contentful'>
                {documentToReactComponents(page?.contents?.json, contentfulReactComponentsOptions)}
              </div>
            )}

            {page.type === 'Activity' && page.activityType !== 'Coloring Sheets' && (
              <div className='contentful'>
                {documentToReactComponents(activityJson, contentfulReactComponentsOptions)}
              </div>
            )}

            {page.type === 'Activity' && page.activityType === 'Coloring Sheets' && <Book {...props} />}

            {page.type === 'Book' && <Book {...props} />}

            {page.type !== 'Article' && <Recommended currentArticle={page.contentful_id} type={page.type} />}

            {seriesInformation && seriesInformation.length > 0 && <SeriesInformation description={seriesInformation} />}

            <Share author={page?.author} />

            <Login />

            {page.sources && <Sources contents={page.sources?.json} />}

            <Recommended currentArticle={page.contentful_id} topics={page?.primaryTopics} />
          </div>
          <Sidebar
            sidebarContent={sidebarContent}
            showFeaturedVideo={showFeaturedVideo ?? true}
            showFeaturedActivities={showFeaturedActivities ?? true}
          />
        </div>
      </div>
    </Layout>
  )
}

export default DetailPage

export const pageQuery = graphql`
  query detailQuery($id: String!) {
    page: contentfulActivityArticleBookOrVideoDetailPage(id: { eq: $id }) {
      id
      contentful_id
      title
      type
      activityType
      primaryTopics {
        slug
        name
        id
      }
      secondaryTopics {
        name
        id
        seriesDescription {
          internal {
            content
          }
        }
      }
      subline {
        childMarkdownRemark {
          html
        }
      }
      contents {
        json
      }
      author {
        name
        image {
          file {
            url
          }
        }
      }
      sources {
        json
      }
      bookImage: Book_Image {
        file {
          url
        }
      }
      bookDescription: Book_Description {
        json
      }
      bookDownload: Book_DownloadPDF {
        file {
          url
        }
      }
      bookLink: Book_ReadOnlineLink
      youtubeVideoId
      showFeaturedVideo
      showFeaturedActivities
    }
    sidebarContent: contentfulActivityArticleBookOrVideoDetailPage(id: { eq: $id }) {
      pageFeaturedDownloadLabel: featuredDownloadLabel
      pageFeaturedDownloadTitle: featuredDownloadTitle
      pageFeaturedDownloadImage: featuredDownloadImage {
        file {
          url
        }
      }
      pageFeaturedDownloadAsset: featuredDownloadAsset {
        file {
          fileName
          url
        }
      }
      pageFeaturedDownloadLink: featuredDownloadLink
      pageFeaturedDownloadCta: featuredDownloadCta
      pageFeaturedVideo: featuredVideo {
        title
        contentful_id
        internal {
          type
        }
        node_locale
        slug
        type
      }
      pageFeaturedService: featuredService {
        name
        headline
        slug
        internal {
          type
        }
        node_locale
        slug
        type
      }
      pageFeaturedActivities: featuredActivities {
        title
        slug
        internal {
          type
        }
        node_locale
        slug
        type
      }
      pageFeaturedQuote: featuredQuote {
        quote {
          quote
        }
        person
        personSubline
      }
    }
  }
`
