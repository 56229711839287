import React from 'react'

const Pullquote = ({ quote, author, caption }) => {
  const quotemarkStyles = {
    fontFamily: 'Arial',
    fontSize: '5rem',
    color: '#afcedd'
  }
  return (
    <div className='flex flex-col items-center'>
      <div className='h-16 font-bold text-center' style={quotemarkStyles} aria-hidden>
        &ldquo;
      </div>
      <div className='text-2xl italic text-center leading-tight'>{quote}</div>
      <div className='mt-4 flex text-sm leading-snug'>
        <div className='mr-2 font-bold'>&mdash;</div>
        <div>
          <span className='block font-bold'>{author}</span>
          <span>{caption}</span>
        </div>
      </div>
    </div>
  )
}

export default Pullquote
