import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'

import ShareButton from '~components/detail/share-button'

const Share = ({ author }) => {
  return (
    <div className='my-8'>
      <hr className='accent-rule' aria-hidden />
      <div className='lg:flex justify-between items-center py-4'>
        <div className='flex flex-col md:flex-row md:items-center'>
          {author && author.name && (
            <>
              <img
                src={author.image.file.url + '?fm=jpg&fl=progressive&w=320&q=60'}
                alt={author.name}
                className='w-1/2 lg:w-auto lg:h-10'
              />
              <div className='flex flex-col w-1/2 md:ml-4 mt-4 md:mt-0'>
                <span className='extended text-gray-dark whitespace-no-wrap'>
                  <FormattedMessage id='contributed-by' />:
                </span>
                <div className='headline-6 mt-1'>{author.name}</div>
              </div>
            </>
          )}
        </div>
        <div className='flex-shrink-0 mt-4 lg:mt-0 lg:pl-2'>
          <ShareButton label='share-prompt' />
        </div>
      </div>
      <hr className='accent-rule' aria-hidden />
    </div>
  )
}

export default Share
