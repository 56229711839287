import React from 'react'
import { FormattedMessage } from 'react-intl'

import AnchorLink from '~components/detail/anchor-link'

const TableOfContents = props => {
  const headlines = props.contents
    .filter(headline => headline.nodeType === 'heading-2')
    .map(headline => <AnchorLink key={headline} {...headline} />)
  if (props.contents.filter(headline => headline.nodeType === 'heading-2').length > 2) {
    return (
      <>
        <div className='flex flex-col my-16 p-8 bg-gray-light'>
          <div className='headline-4'>
            <FormattedMessage id='table-of-contents' />
          </div>
          <hr className='accent-rule my-8' aria-hidden />
          <ul className='space-y-8'>{headlines}</ul>
        </div>
      </>
    )
  } else {
    return null
  }
}
export default TableOfContents
