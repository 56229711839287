import React from 'react'

const Headline = props => {
  const id = props.contents
    .toString()
    .toLowerCase()
    .replace(/[^\w]/gi, '')
  const headlineClass = 'headline-' + props.level

  if (props.level === '2') {
    return (
      <>
        <div id={id} className={`headline-2 ${props.headingType ? 'headline-list' : ''}`}>
          {props.contents}
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={headlineClass}>{props.contents}</div>
      </>
    )
  }
}

export default Headline
