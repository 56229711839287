import React, { useState } from 'react'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import Linkify from 'react-linkify'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Sources = ({ contents }) => {
  const [isOpen, setIsOpen] = useState(true)

  if (contents) {
    return (
      <div className='sources'>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className='flex justify-between items-center w-full my-4 px-4 py-2 border border-gray'
        >
          <div className='headline-4'>
            <FormattedMessage id='show-sources' />
          </div>
          <div className='icon-large bg-purple text-white'>
            <em className={cx('fas', isOpen ? 'fa-minus' : 'fa-plus')} />
          </div>
        </button>
        <div className={cx(!isOpen && 'hidden', 'contentful')}>
          <Linkify properties={{ target: '_blank', title: 'Source' }}>{documentToReactComponents(contents)}</Linkify>
        </div>
      </div>
    )
  }
}

export default Sources
