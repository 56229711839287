import React from 'react'

const CalloutCard = props => {
  // general <CalloutCard> classes
  const cardClasses = `relative flex h-auto w-full border-t-8 border-blue bg-white p-4 overflow-hidden shadow-xl`
  const iconWrapperClasses = `absolute z-20 order-first flex justify-center items-center w-32 h-32 -ml-16 -mt-20 rounded-full text-4xl text-white`
  const iconClasses = `fas block pl-8 pt-12`

  // additional <FactCard> classes
  const factCardClasses = `${cardClasses} border-blue`
  const factCardIconWrapperClasses = `${iconWrapperClasses} bg-blue`
  const factCardIconClasses = `${iconClasses} fa-graduation-cap`

  // additional <IdeaCard> classes
  const ideaCardClasses = `${cardClasses} border-green`
  const ideaCardIconWrapperClasses = `${iconWrapperClasses} bg-green`
  const ideaCardIconClasses = `${iconClasses} fa-lightbulb`

  if (props.type === 'Fact') {
    return (
      <div className={factCardClasses}>
        <p className='headline-5 order-last pl-24 pr-12'>{props.content}</p>
        <span className={factCardIconWrapperClasses}>
          <em className={factCardIconClasses} />
        </span>
      </div>
    )
  } else if (props.type === 'Idea') {
    return (
      <div className={ideaCardClasses}>
        <p className='headline-5 order-last pl-24 pr-12'>{props.content}</p>
        <span className={ideaCardIconWrapperClasses}>
          <em className={ideaCardIconClasses} />
        </span>
      </div>
    )
  } else {
    return null
  }
}

export default CalloutCard
