import React from 'react'
import PropTypes from 'prop-types'
import { indexOf } from 'lodash'
import { FormattedMessage } from 'react-intl'

const SeriesInformation = ({ description }) => {
  if (description) {
    return (
      <div className='my-16'>
        <div className='headline-3'>
          <FormattedMessage id='series-info' />
        </div>
        <p className='body-4'>{description}</p>
      </div>
    )
  } else {
    return null
  }
}

SeriesInformation.propTypes = {
  description: PropTypes.string
}

export default SeriesInformation
